* {
  padding: 0;
  margin: 0;
  box-sizing: 0;
  font-family: 'Roboto', sans-serif;
  /* color: #3A3A3A; */
}

.content {
  width: 100%;
  height: 100vh;
  border-radius: 0px;
}

.Button {
  font-size: 5px;
  font-weight: bold;
  text-transform: initial;
  background-color: red;
}

.icon {
  font-size: 23px;
}